/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

require('../css/frontend/animate.min.css');
require('../css/frontend/default.css');
require('../css/frontend/magnific-popup.css');
require('../css/frontend/owl.carousel.min.css');
require('../css/frontend/responsive.css');
require('../css/frontend/slick.css');
require('../scss/frontend/import-bootstrap.scss');
require('../css/frontend/style.css');
require('../css/frontend/text-headline.css');
require('../css/frontend/themify-icons.css');
require('../scss/frontend/style.scss');

// JS
require('./frontend/vendor/modernizr-3.5.0.min.js');
const imagesLoaded = require('imagesloaded');
const $ = require('jquery');
// provide jQuery argument
imagesLoaded.makeJQueryPlugin($);
require('bootstrap');
require('wowjs');
require('google-maps');
require('./frontend/owl.carousel.min.js');
require('./frontend/one-page-nav-min.js');
require('./frontend/jquery.counterup.min.js');
require('./frontend/jquery.waypoints.min.js');
require('./frontend/slick.min.js');
require('./frontend/ajax-form.js');
require('./frontend/jquery.scrollUp.min.js');
require('./frontend/jquery.magnific-popup.min.js');
require('./frontend/plugins.js');
require('./frontend/main.js');
require('./frontend/custom.js');

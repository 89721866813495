$('.main-tabs a').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('active')) {
        $(this).parent().find('.active').removeClass('active');
        $(this).addClass('active');
        $('.main-tabs-content').find('.active').removeClass('active');
        const selector = $(this).attr('href');
        $('.main-tabs-content ' + selector).addClass('active');
        if ('#tab-send' === selector) {
            $('.main-menu ul.navbar-nav li a.nav-link[href="/#features-manage"]').attr('href', '/#features');
            $('.main-menu ul.navbar-nav li a.nav-link[href="/#about-manage"]').attr('href', '/#about');
        } else {
            $('.main-menu ul.navbar-nav li a.nav-link[href="/#features"]').attr('href', '/#features-manage');
            $('.main-menu ul.navbar-nav li a.nav-link[href="/#about"]').attr('href', '/#about-manage');
        }
    }
});
